import { ModuleConfig } from "types/module";
import { ModuleBundle } from "./ModuleBundle";

const module1cConfig: ModuleConfig = {
  imageCount: 1,
  module: ModuleBundle,
  moduleTitle1: true,
  moduleTitle2: false,
  moduleCta1: true,
  moduleCta2: false,
  moduleBody: false,
  imgSml: {
    desktop: 338,
    mobile: 335,
  },
  enableCoverPhotoUpload: true,
  enableLinkOverride: true,
  showItemSortSelector: true,
  retrieveItems: true,
};

const modulesConfig: Record<string, ModuleConfig> = {
  module1c: module1cConfig,
  moduleBundle: module1cConfig,
  default: module1cConfig,
};

export { modulesConfig };
