import React from "react";

export function ImgFromCDN({
  src,
  width,
  alt = "",
  cdnDomain = "",
  dpr = [1, 2],
  // We don't import it from other lib since lib isn't publishable :()
  srcSetLoader,
  ...rest
}: {
  src: string;
  cdnDomain?: string;
  width: number;
  alt?: string;
  dpr?: number[];
  [x: string]: unknown;
  srcSetLoader: (
    cfDomain: string,
    url: string,
    width: number,
    dpr: number[]
  ) => { src: string; srcSet: string };
}): JSX.Element {
  const { src: imgSrc, srcSet } = srcSetLoader(cdnDomain, src, width, dpr);
  return (
    // TODO: use next/image instead!
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={imgSrc}
      srcSet={dpr.length > 1 ? srcSet : undefined}
      width={width}
      {...rest}
      alt={alt}
    />
  );
}
