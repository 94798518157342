import React, { ReactElement } from "react";
import { GqlModule } from "types/module";
import { modulesConfig } from "./modulesConfig";

// TODO: rewrite it better somehow, w/o ts-ignore + lazy-loading
const ModuleRenderer = (props: GqlModule): ReactElement => {
  const { type } = props;
  const currentModule = modulesConfig[type] || modulesConfig.default;
  const { module, ...config } = currentModule;
  return React.createElement(module, { config, data: props });
};

export { ModuleRenderer };
