import React, { useContext } from "react";
import { GetServerSideProps } from "next";
import Head from "next/head";
import { LogContext } from "context/log";
import { Button } from "@pepdirect/ui/button";
import { ImgFromCDN } from "@pepdirect/ui/ImgFromCDN";
import { Heading } from "@pepdirect/ui/heading";
import { PANTRYSHOP_COLORS } from "@pepdirect/ui/theme/PantryShopTheme";
import { ModuleRenderer } from "components/Modules/ModuleRenderer";
import { GqlModule } from "types/module";
import { META_TITLE, CANONICAL_URL_BASE } from "constants/meta";
import st from "./home.module.scss";
import { getCloudflareImageSrcSet } from "@pepdirect/helpers/cloudflareImages";
import { endpoints } from "config";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import createSsrClient from "services/graphql/ssr-client";
import {
  ModulesQueryVariables,
  ModulesDocument,
  ModulesQuery,
} from "services/graphql/generated";
import { PAGE_TYPE_ENUM } from "@pepdirect/shared/types";
import { generatePageId } from "@pepdirect/helpers/analyticsLogger";

const sortModulesDescending = (a: GqlModule, b: GqlModule): number => {
  return new Date(a.publishedAt) < new Date(b.publishedAt) ? 1 : -1;
};

interface HomePageProps {
  modules: GqlModule[];
}
// TODO: pagination ?
export const getServerSideProps: GetServerSideProps<HomePageProps> = async (
  appContext
) => {
  try {
    const gqlClient: ApolloClient<NormalizedCacheObject> = createSsrClient(
      appContext.req
    );

    const { data } = await gqlClient.query<ModulesQuery, ModulesQueryVariables>(
      { query: ModulesDocument }
    );

    const modules = (data.modules || []).sort(sortModulesDescending);

    return {
      props: {
        modules,
      },
    };
  } catch (error) {
    return {
      props: {
        modules: [],
      },
    };
  }
};

export default function Home({ modules }: HomePageProps): JSX.Element {
  const { useLogPageView } = useContext(LogContext);
  useLogPageView({
    pageType: PAGE_TYPE_ENUM.home,
    pageCategory: undefined,
    pageTitle: META_TITLE,
    id: generatePageId("home"),
  });

  return (
    <div>
      <Head>
        {/* TODO: og tags */}
        <title>{META_TITLE}</title>
        <link rel="canonical" href={CANONICAL_URL_BASE} />
      </Head>
      {/* REGULAR PAGE CONTENT BELOW; UNCOMMENT WHEN "GIVE A BUNDLE" IS DONE (~AUG/SEP 2020) */}
      {/* <div className={st.hero}>
        <div className={st.heroAction}>
          <Heading level="h1" size="xxl">
            Delivering favorite snacks {"&"} beverages straight to your door.
          </Heading>
          <Button internalLink="/kit/beverages">Get Started</Button>
        </div>
        <figure className={st.heroImageWrapper}>
          <ImgFromCDN
            className={st.heroImage}
            width={400}
            src="/static-images/pantry-shop-icons/pantryshop-header-hero.png"
            alt="Some products available on Pantry Shop: Life cereal box, Quaker chewy snacks, bottle of Tropicana juice, bag of Lay's chips, and Smartfood popcorn"
          />
        </figure>
      </div> */}
      {/* BUNDLE PROMO BELOW */}
      <div className={st.hero}>
        <div className={st.heroAction}>
          <Heading level="h1" size="xxl">
            Bundles & Beverages
          </Heading>
          <p>
            Your favorites all in one place, delivered right to your door. Enjoy
            free shipping on all orders!
          </p>
          <Button internalLink="/kit/all-bundles">Shop All Bundles</Button>
        </div>
        <figure className={st.heroImageWrapper}>
          <ImgFromCDN
            srcSetLoader={getCloudflareImageSrcSet}
            cdnDomain={endpoints.cdn}
            className={st.heroImage}
            src={`/static-images/pantry-shop-icons/pantryshop_hero_spring.png`}
            alt="Products in front of pastel painted flowers"
            width={400}
          />
        </figure>
      </div>
      {modules.length > 0 && (
        <>
          <div className={st.modulesTitle} id="see-all-bundles">
            <Heading
              level="h2"
              size="xl"
              color={PANTRYSHOP_COLORS.orange}
              center
            >
              Food & Beverages for every occasion
            </Heading>
          </div>
          <div className={st.modulesWrapper}>
            {modules.map((module) => (
              <ModuleRenderer key={module.id} {...module} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
